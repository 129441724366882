/* INVESTOR FACE ICONS */
import WarrenBuffet from "assets/images/investors/warren-buffet.png";
import JackBogle from "assets/images/investors/jack-bogle.png";
import Benjamin from "assets/images/investors/benjamin.png";

import WarrenBuffetFull from "assets/images/investors/warren-buffet-full.png";
import PeterLynchFull from "assets/images/investors/peter-lynch-full.png";
import JackBogleFull from "assets/images/investors/jack-bogle-full.png";

/* ETF ICONS */
import ETF01 from "assets/images/etf-icons/etf-01.png";
import ETF02 from "assets/images/etf-icons/etf-02.png";
import ETF03 from "assets/images/etf-icons/etf-03.png";
import ETF04 from "assets/images/etf-icons/etf-04.png";
import ETF05 from "assets/images/etf-icons/etf-05.png";
import ETF06 from "assets/images/etf-icons/etf-06.png";
import ETF07 from "assets/images/etf-icons/etf-07.png";
import ETF08 from "assets/images/etf-icons/etf-08.png";

import ETF01Detail from "assets/images/etf-icons/etf-01-detail.png";
import ETF02Detail from "assets/images/etf-icons/etf-02-detail.png";
import ETF03Detail from "assets/images/etf-icons/etf-03-detail.png";
import ETF04Detail from "assets/images/etf-icons/etf-04-detail.png";
import ETF05Detail from "assets/images/etf-icons/etf-05-detail.png";
import ETF06Detail from "assets/images/etf-icons/etf-06-detail.png";
import ETF07Detail from "assets/images/etf-icons/etf-07-detail.png";
import ETF08Detail from "assets/images/etf-icons/etf-08-detail.png";

/* BROKER */
import Alpaca from "assets/images/logos/alpaca.png";

const stocksList = [
  {
    id: 1,
    image: WarrenBuffet,
    name: "Warren Buffet",
    strategyName: "90 / 10 Strategy",
    riskScore: 3.24,
    returnPercentage: 11.77,
    type: "investor",
    underlying: "_BUFFETT",
    about:
      "It has the opportunity to grow quickly, but it can drop just as fast. To balance this risk, bonds that are guaranteed by the U.S. government are added.",
    investorDetails: {
      image: WarrenBuffetFull,
      heading: "Who is Warren Buffet",
      list: [
        "Averaged 20% per year for over 50 years, making him one of the best investors in history",
        "Says the average person should just follow the 90/10 portfolio to reach wealth",
        "Pledged to donate 99% of his wealth",
      ],
      composition: [
        {
          name: "VOO",
          description:
            "Invests in the 500 largest companies in the U.S. This is one of the most popular and best-performing investments in the world.",
          percentage: 90,
        },
        {
          name: "BILS",
          description:
            "Invests in bonds that are guaranteed by the U.S. government. It is extremely safe, so the return is lower.",
          percentage: 10,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who are not afraid of moderate to higher risk.",
        },
        {
          description:
            "Potential for higher returns, because it invests primarily in stocks instead of bonds.",
        },
        {
          description:
            "This strategy uses bonds as a way to balance out some risk.",
        },
        {
          description:
            "Because it invests in the largest 500 companies in the U.S., most money is invested in tech companies like Nvdia, Apple, and Meta.",
        },
      ],
      drawbacks: [
        {
          description:
            "It can sometimes be too volatile for people who do not like risk.",
        },
        {
          description:
            "If the market were to face an extreme decline, it could take longer for this strategy to recover.",
        },
      ],
    },
  },
  // {
  //   id: 2,
  //   image: RayDalio,
  //   name: "Ray Dalio",
  //   strategyName: "All Weather Strategy",
  //   riskScore: 3.6,
  //   returnPercentage: 9.7,
  //   underlying: "_RAY",
  //   about:
  //     "This is some text about the strategy overview that would explain what it is, where it was derived from and who it’s for...",
  //   type: "investor",
  //   investorDetails: {
  //     image: RayDalioFull,
  //     heading: " Who is Ray Dalio",
  //     list: [
  //       " Averaged 20% per year for over 50 years, making him one of the best investors in history",
  //       " Says the average person should just follow the 90/10 portfolio to reach wealth",
  //       "Pledged to donate 99% of his wealth",
  //     ],
  //     composition: {
  //       initial: 33.33,
  //       secondary: 33.33,
  //       tertiary: 33.33,
  //       others: ` The Bloomberg 3-12 Month T-Bill ETF (BILLS) helps
  //                             you invest in short-term bonds backed by the U.S.
  //                             government.`,
  //     },
  //   },
  // },

  {
    id: 4,
    image: JackBogle,
    name: "Jack Bogle",
    strategyName: "3 Fund Strategy",
    riskScore: 2.93,
    returnPercentage: 8.01,
    type: "investor",
    underlying: "_BOGLE",
    about:
      "This is a good balance between growing at a solid pace and not rising or dropping too quickly. It’s not the fastest, but it’s not the slowest either.",
    investorDetails: {
      image: JackBogleFull,
      heading: " Who is Jack Bogle",
      list: [
        "Founder of Vanguard, which now manages $7.2 trillion",
        "Popularized index funds and low-cost, passive investing",
        "Famous for trying to help the average investor grow their wealth safely",
      ],
      composition: [
        {
          name: "VTI",
          description:
            "Invests in every stock that is available in the U.S. stock market (3500+ companies). A passive way to invest in the U.S. economy.",
          percentage: 64,
        },
        {
          name: "BND",
          description:
            "Invests in intermediate-term bonds that are guaranteed by the U.S. government. It is safer and can provide reliable dividend income.",
          percentage: 20,
        },
        {
          name: "VXUS",
          description:
            "Invests in thousands of companies outside of the U.S., primarily in emerging countries, the Pacific, and Europe.",
          percentage: 16,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who prefer mild risk levels.",
        },
        {
          description:
            "This strategy is extremely diverse and effectively invests in over 11,000 different companies, both within the U.S. and internationally.",
        },
        {
          description:
            "This strategy does not sacrifice too much in the trade-off between potential returns and potential risk.",
        },
      ],
      drawbacks: [
        {
          description:
            "It can be hard to split smaller amounts of money across three different investments without using fractional shares.",
        },
        {
          description:
            "By being exposed to international companies, it opens this strategy up to more potential risks (i.e. currency, war, geopolitical).",
        },
      ],
    },
  },
  {
    id: 3,
    image: Benjamin,
    name: "Benjamin Graham",
    strategyName: "Defensive Investor",
    riskScore: 2.59,
    returnPercentage: 7.74,
    underlying: "_GRAHAM",
    about:
      "It does not grow fast, but it is very steady. During previous market crashes, this strategy only dropped by half as much as the rest of the stock market.",
    type: "investor",
    investorDetails: {
      image: PeterLynchFull,
      heading: "Who is Benjamin Graham",
      list: [
        "Father of value investing and mentor to Warren Buffett",
        "Wrote the most famous book on investing, The Intelligent Investor",
        "Averaged a 17% annual return for 30 years from 1926 - 1956 (30 years)",
      ],
      composition: [
        {
          name: "BND",
          description:
            "Invests in intermediate-term bonds that are guaranteed by the U.S. government. It is safer and can provide reliable dividend income.",
          percentage: 40,
        },
        {
          name: "VTI",
          description:
            "Invests in every stock that is available in the U.S. stock market (3500+ companies). A passive way to invest in the U.S. economy.",
          percentage: 60,
        },
      ],
      benefits: [
        {
          description:
            "This strategy is best for people who want to grow their money steadily.",
        },
        {
          description:
            "This strategy pays consistent dividend income because it invests heavily in bonds.",
        },
        {
          description:
            "During the Great Recession, the rest of the stock market dropped over 48% while this strategy only dropped 28%.",
        },
        {
          description:
            "After the Great Recession, the stock market took 26 months to recover, while this strategy only took 14 months to recover.",
        },
      ],
      drawbacks: [
        {
          description:
            "Over time, it may grow much slower than other investment strategies because it takes less risk.",
        },
        {
          description:
            "During major market rises, this strategy may capture as much opportunity as others.",
        },
      ],
    },
  },
  {
    id: 5,
    image: ETF01,
    name: "Ticker: VOO",
    strategyName: "500 Largest Companies",
    riskScore: 3.6,
    returnPercentage: 13.33,
    underlying: "VOO",
    type: "etf",
    about:
      "Invests in a the 500 biggest U.S. companies. Most of it is invested in tech companies, but it includes almost every industry, from AI chips to candy bars.",
    investorDetails: {
      image: ETF01Detail,
      heading: "What is VOO?",
      list: [
        `Follows the top 500 big companies in the U.S.`,
        `One of the best-performing funds, offering steady long-term growth.`,
        `Averaged around 10% annual return since its start.`,
      ],
      holdings: [
        {
          name: "Apple Inc. (AAPL)",
          percentage: 6.97,
          link: false,
        },
        {
          name: "Microsoft Corp (MSFT)",
          percentage: 6.54,
          link: false,
        },
        {
          name: "NVIDIA Corp (NVDA)",
          percentage: 6.2,
          link: false,
        },
        {
          name: "Amazon.com Inc (AMZN)",
          percentage: 3.45,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://investor.vanguard.com/investment-products/etfs/profile/voo",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Suitable for those comfortable with moderate risk for long-term growth",
        },
        {
          description:
            "Focuses on large, well-known companies, especially in tech, boosting potential returns",
        },
        {
          description:
            "Low-cost and easy to access, making it a popular choice for investors",
        },
      ],
      drawbacks: [
        {
          description:
            "Can be too risky for those who prefer stable or guaranteed returns",
        },
        {
          description:
            "If the market drops significantly, it may take time to recover due to its high stock exposure",
        },
      ],
    },
  },
  {
    id: 6,
    image: ETF02,
    name: "Ticker: QQQ",
    strategyName: "Big tech companies",
    riskScore: 3.85,
    returnPercentage: 18.28,
    type: "etf",
    about:
      "Invests in a the 500 biggest U.S. companies. Most of it is invested in tech companies, but it includes almost every industry, from AI chips to candy bars.",
    underlying: "QQQ",
    investorDetails: {
      image: ETF02Detail,
      heading: "What is QQQ?",
      list: [
        `Tracks the 100 biggest tech companies in the US`,
        `Focuses on fast-growing companies, mainly in tech`,
        `Known for its strong growth over time`,
      ],
      holdings: [
        {
          name: "Apple Inc. (AAPL)",
          percentage: 8.85,
          link: false,
        },
        {
          name: "Microsoft Corp (MSFT)",
          percentage: 7.99,
          link: false,
        },
        {
          name: "NVIDIA Corp (NVDA)",
          percentage: 7.78,
          link: false,
        },
        {
          name: "Broadcom Inc (AVGO) ",
          percentage: 5.31,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://www.invesco.com/qqq-etf/en/about.html?utm_campaign=BL_QQQ_BAU_G_S_ININV_PROS_Brand&utm_source=Google&utm_medium=cpc&utm_content=qqq-cpc-paid-text&utm_term=12190225115_117285846619&utm_client=placeholder&utm_ver=placeholder&gad_source=1&gclid=Cj0KCQjw6oi4BhD1ARIsAL6pox1HI-X5o9l98c1p-9p-DPWS4HuXRmuPT8360ZKFH_4Nh0XBibb0A9gaAq4WEALw_wcB",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Suitable for those comfortable with moderate risk for long-term growth",
        },
        {
          description:
            "Focuses on large, well-known companies, especially in tech, boosting potential returns",
        },
        {
          description:
            "Low-cost and easy to access, making it a popular choice for investors",
        },
      ],
      drawbacks: [
        {
          description:
            "Can be too risky for those who prefer stable or guaranteed returns",
        },
        {
          description:
            "If the market drops significantly, it may take time to recover due to its high stock exposure",
        },
      ],
    },
  },
  {
    id: 7,
    image: ETF03,
    name: "Ticker: SCHD",
    strategyName: "Dividend investing",
    riskScore: 3.6,
    returnPercentage: 11.71,
    type: "etf",
    about:
      "Invests mostly in large health care, finance, and industrial companies that use their extra profits to pay investors for owning the stock.",
    underlying: "SCHD",
    investorDetails: {
      image: ETF03Detail,
      heading: "What is SCHD?",
      list: [
        "Invests in big companies that pay money to their investors, called dividends",
        "Focuses on companies that are more mature and stable",
        "Great for people who want steady income with some growth",
      ],
      holdings: [
        {
          name: "Home Depot (HD)",
          percentage: 4.41,
          link: false,
        },
        {
          name: "Blackrock (BLK)",
          percentage: 4.23,
          link: false,
        },
        {
          name: "Cisco Systems (CSCO)",
          percentage: 4.22,
          link: false,
        },
        {
          name: "Chevron Corp (CVX)",
          percentage: 4.16,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink: "https://www.schwabassetmanagement.com/products/schd",
          link: true,
        },
      ],
      benefits: [
        { description: "Good for people who want steady money from investing" },
        { description: "Safer than tech-focused investments" },
        { description: "Offers a mix of stability and growth for investors" },
      ],
      drawbacks: [
        {
          description: "Might not grow as quickly as tech-focused investments",
        },
        {
          description:
            "Could make less money than other investments if the market does really well",
        },
        {
          description:
            "Invests only in U.S. companies, so you wouldn’t own any international investments",
        },
      ],
    },
  },
  {
    id: 8,
    image: ETF04,
    name: "Ticker: GLD",
    strategyName: "Invest in Gold",
    riskScore: 2.95,
    returnPercentage: 7.58,
    type: "etf",
    about:
      "Investing in gold can be a safer option when the stock market’s shaky, because gold generally holds its value when stocks drop.",
    underlying: "GLD",
    investorDetails: {
      image: ETF04Detail,
      heading: "What is GLD?",
      list: [
        "This lets you invest in gold without needing to buy physical gold",
        "GLD follows the price of gold for you",
        "Great to diversify your investments and keep up with inflation",
      ],
      holdings: [
        {
          name: "Physical gold bullion",
          percentage: 99.9,
          link: false,
        },
        {
          name: "Cash",
          percentage: 0.1,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://www.ssga.com/us/en/intermediary/etfs/spdr-gold-shares-gld",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Can protect your money during times of market uncertainty or inflation",
        },
        {
          description:
            "Easiest way to invest in gold without needing to buy physical gold",
        },
        {
          description:
            "Good for diversifying your investments, reducing overall risk",
        },
      ],
      drawbacks: [
        {
          description:
            "Gold prices can be unpredictable, leading to price swings",
        },
        {
          description:
            "No dividends or interest payments, so this investment doesn’t generate any income",
        },
        {
          description:
            "Doesn’t grow your money like stocks can, because you’re investing in gold instead of a business",
        },
      ],
    },
  },
  {
    id: 9,
    image: ETF05,
    name: "Ticker: VUG",
    strategyName: "Big Growth Companies",
    riskScore: 3.9,
    returnPercentage: 15.54,
    type: "etf",
    about:
      "Invests in the largest companies in the U.S. that are expected to grow quickly. It has slightly more risk, but can lead to higher returns.",
    underlying: "VUG",
    investorDetails: {
      image: ETF05Detail,
      heading: "What is VUG?",
      list: [
        "Invests in big, fast-growing U.S. companies",
        "Mostly invests in the technology industry",
        "Known for its strong growth over time",
      ],
      holdings: [
        {
          name: "Apple Inc. (AAPL)",
          percentage: 13.01,
          link: false,
        },
        {
          name: "Microsoft Corp (MSFT)",
          percentage: 12.09,
          link: false,
        },
        {
          name: "NVIDIA Corp (NVDA)",
          percentage: 10.88,
          link: false,
        },
        {
          name: "Amazon.com Inc (AMZN)",
          percentage: 4.55,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://advisors.vanguard.com/investments/products/vug/vanguard-growth-etf#portfolio",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "In addition to tech, this ETF covers things like like real estate, healthcare, and consumer goods",
        },
        {
          description:
            "It is volatile, but may be less volatile than growth ETFs that only invest in tech companies",
        },
        {
          description:
            "Includes a range of company sizes, from mega-sized to mid-sized public companies",
        },
      ],
      drawbacks: [
        {
          description:
            "This ETF can still be relatively volatile with moderate price swings up and down",
        },
        {
          description:
            "It may not provide as much safety during a large market drop",
        },
        {
          description:
            "It does not include any small-sized companies which can limit growth potential",
        },
      ],
    },
  },
  {
    id: 10,
    image: ETF06,
    name: "Ticker: IWM",
    strategyName: "Smaller Companies",
    riskScore: 4.3,
    returnPercentage: 8.74,
    type: "etf",
    about:
      "It focuses on small companies with big growth potential. It’s riskier but has a chance for higher returns than ETFs focused on big companies.",
    underlying: "IWM",
    investorDetails: {
      image: ETF06Detail,
      heading: "What is IWM?",
      list: [
        "Invests in 2,000 smaller U.S. companies with growth potential",
        "Great for investing in smaller companies if you only invest in large companies",
        "Consider it a “high risk, high reward” kind of ETF",
      ],
      holdings: [
        {
          name: "FTAI Aviation (FTAI)",
          percentage: 0.56,
          link: false,
        },
        {
          name: "Vaxcyte Inc (PCVX)",
          percentage: 0.51,
          link: false,
        },
        {
          name: "Sprouts Farmers Market (SFM)",
          percentage: 0.43,
          link: false,
        },
        {
          name: "Insmed (INSM)",
          percentage: 0.43,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://www.ishares.com/us/products/239710/ishares-russell-2000-etf",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "One investment that helps you invest across 2,000 companies",
        },
        {
          description:
            "Smaller companies can sometimes grow the fastest during certain periods",
        },
        {
          description:
            "Good for diversifying your investments, reducing overall risk",
        },
      ],
      drawbacks: [
        {
          description:
            "Smaller companies may carry more risk because they are less established",
        },
        { description: "It’s generally more volatile" },
        {
          description:
            "Doesn’t grow your money like stocks can, because you’re investing in gold instead of a business",
        },
      ],
    },
  },
  {
    id: 11,
    image: ETF07,
    name: "Ticker: VXUS",
    strategyName: "Global Economy",
    riskScore: 3.45,
    returnPercentage: 5.34,
    type: "etf",
    about:
      "VXUS invests in companies outside the U.S., giving you access to global markets. It spreads risk but can be more unpredictable than U.S. funds.",
    underlying: "VXUS",
    investorDetails: {
      image: ETF07Detail,
      heading: "What is VXUS?",
      list: [
        "Invests in thousands of international companies",
        "Great for investing in companies outside of the U.S.",
        "Covers a wide range of economies and countries",
      ],
      holdings: [
        {
          name: "Taiwan Semiconductor (TSM)",
          percentage: 2.15,
          link: false,
        },
        {
          name: "Novo Nordisk (NOVO)",
          percentage: 1.28,
          link: false,
        },
        {
          name: "ASML (ASML)",
          percentage: 1.07,
          link: false,
        },
        {
          name: "Tencent (TCEHY)",
          percentage: 0.92,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://investor.vanguard.com/investment-products/etfs/profile/vxus#portfolio-composition",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Good way to diversify into international companies, beyond the U.S. market",
        },
        {
          description:
            "Can outperform U.S. stocks during periods of strong global growth",
        },
        {
          description:
            "Exposes you to high-quality companies that domestic ETFs don’t offer",
        },
      ],
      drawbacks: [
        {
          description:
            "Sometimes it’s more volatile than U.S.-focused funds due to international risks",
        },
        { description: "Currency exchange rates can impact performance" },
        {
          description:
            "It’s exposed to developing countries which can carry more risk",
        },
      ],
    },
  },
  {
    id: 12,
    image: ETF08,
    name: "Ticker: DIA",
    strategyName: "U.S Economy",
    riskScore: 3.7,
    returnPercentage: 8.48,
    type: "etf",
    about:
      "DJIA tracks 30 big companies that contribute significant amounts of economic activity in the U.S. It’s another way to invest in the U.S. economy.",
    underlying: "DIA",
    investorDetails: {
      image: ETF08Detail,
      heading: "What is DIA?",
      list: [
        "Tracks the Dow Jones Industrial Average",
        "Invest in 30 large, stable, and foundational companies",
        "This ETF was launched in 1998",
      ],
      holdings: [
        {
          name: "United Health (UNH)",
          percentage: 9.24,
          link: false,
        },
        {
          name: "Goldman Sachs (GS)",
          percentage: 7.79,
          link: false,
        },
        {
          name: "Microsoft (MSFT)",
          percentage: 6.43,
          link: false,
        },
        {
          name: "Home Depot (HD)",
          percentage: 6.34,
          link: false,
        },
        {
          name: "See all link",
          percentage: null,
          redirectLink:
            "https://www.ssga.com/us/en/intermediary/etfs/spdr-dow-jones-industrial-average-etf-trust-dia",
          link: true,
        },
      ],
      benefits: [
        {
          description:
            "Invests in a wide range of well-known companies with strong track records",
        },
        {
          description:
            "Has consistently delivered balanced investment results since it was launched in 1998",
        },
        {
          description:
            "A steady, long-term growth option for more conservative investors",
        },
      ],
      drawbacks: [
        {
          description:
            "Invests in just 30 companies compared to other ETFs that hold thousands of companies",
        },
        {
          description:
            "Heavily reliant on large, mature companies which may not always grow as quickly",
        },
        {
          description:
            "Less investment into high-growth areas like biotech or technology",
        },
      ],
    },
  },
];

const investorLabels = [
  {
    id: 1,
    label: "Your estimated investment",
    value: "$2,767,166",
    color: "#008037",
  },
  {
    id: 2,
    label: "Amount invested",
    value: "$460,000",
    color: "#50CFD7",
  },
  {
    id: 3,
    label: "Expected net gain",
    value: "$2,307,166",
    color: "#FFF",
  },
];

const brokers = [
  {
    id: 1,
    image: Alpaca,
    name: "Brokerage # ---5914",
    balance: "24,159.88",
    text: "You have $5,157.92 in cash to invest in this account.",
  },
];

export { stocksList, investorLabels, brokers };
