/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

/* PACKAGES */
import { motion } from "framer-motion";
import Select from "react-select";

/* MATERIAL TAILWIND COMPONENTS */
import {
  Card,
  CardBody,
  Button,
  Accordion,
  AccordionHeader,
  AccordionBody,
  Tabs,
  TabsHeader,
  Tab,
  TabsBody,
  TabPanel,
} from "@material-tailwind/react";

/* ANIMATION */
import { containerAnimation, animation } from "config/animation";

/* DATA */
import investmentOptions from "data/investment";
import { counters } from "data/website";

/* IMPORT FORMIK FOR FORM-SUBMIT */
import { useFormik } from "formik";
import * as yup from "yup";

/* UTILS */
import { calculateCompoundInterest } from "utils";

/* ICONS */
import { IoMdAdd } from "react-icons/io";
import { FiMinus } from "react-icons/fi";
import { FaPlay } from "react-icons/fa";

/* CHARTS */
import DashboardChart from "components/calculator/chart";

/* IMAGES */
import VideoPlacholder from "assets/images/website/video-placholder.png";
import Step01 from "assets/images/website/step-01.png";
import Step02 from "assets/images/website/step-02.png";
import Step03 from "assets/images/website/step-03.png";
import Step04 from "assets/images/website/step-04.png";
import BackgroundImage from "assets/images/website/home-bg.png";
import AccountCreation from "components/others/accountCreation";
/* ASSETS */
import WarrenBuffetFull from "assets/images/investors/warren-buffet-full.png";
import WarrenBuffet from "assets/images/investors/warren-buffet.png";
import { stocksList } from "data/investors";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Navigation } from "swiper/modules";
import CurrencyFormat from "react-currency-format";
import { SET_FORMAT } from "config";
import SelectionChart from "components/calculator/selection-chart";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

/* INPUT BOX */
const InputBox = (props) => {
  return (
    <input
      type={props.type}
      name={props.name}
      id={props.id}
      value={props.value}
      onChange={props.onChange}
      placeholder={props.placeholder}
      className="w-full block bg-[#F6F6F6] rounded-md px-5 py-2.5 outline-none focus:outline-none text-base placeholder:font-redhat-regular text-black"
    />
  );
};

/* CONTRIBUTION OPTIONS */
const contributionOptions = [{ value: 1, label: "Monthly" }];

/* VALIDATION SCHEMA FOR LOGIN FORM */
const formValidationSchema = yup.object().shape({
  principalAmount: yup.number().required("Principal Amount is required."),
  monthlyContribution: yup
    .number()
    .required("Monthly Contribution is required."),
  interestRate: yup.number().required("Interest Rate is required."),
  timePeriodRange: yup.object().required("Time Period Range is required."),
  timePeriod: yup.number().required("Time Period is required."),
});

const CUSTOM_ANIMATION = {
  mount: { scale: 1 },
  unmount: { scale: 0.9 },
};

/* CONTRIBUTING OPTIONS */
const contributingOptions = [{ value: 1, label: "Per Month" }];

const Homepage = () => {
  const [activeTab, setActiveTab] = useState("Legendary Investors");
  const [selectedInvestment, setSelectedInvestment] = useState(stocksList[0]);

  /* STATE VARIABLE */
  /* const [selectedInvestment, setSelectedInvestment] = useState({
    id: 1,
    symbol: "VOO",
    name: "S&P 500 ETF (VOO)",
    description: "America's 500 largest companies",
    returnPercentage: "10.26",
    endingText: "%/ year since 1957",
    backgroundColor: "#D7E7FF",
    borderColor: "#5B99F7",
    riskScore: 3.6,
  }); */

  const [stock, setStock] = useState({
    name: "S&P 500 ETF (VOO)",
    returnPercentage: "10.26",
    endingText: "%/ year since 1957",
    symbol: "VOO",
  });

  const [submitLoading, setSubmitLoading] = useState(false);
  const [estimatedInvestment, setEstimatedInvestment] = useState(0);
  const [graphData, setGraphData] = useState([]);
  const [labels, setLabels] = useState([]);
  const [currentTab, setCurrentTab] = useState("login");
  const [currentState, setCurrentState] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [withoutInvestment, setWithoutInvestment] = useState([]);
  const [benefits, setBenefits] = useState(1);
  const [drawbacks, setDrawbacks] = useState(1);
  const [selectedComposition, setSelectedComposition] = useState({
    name: "VOO",
    description:
      "Invests in the 500 largest companies in the U.S. This is one of the most popular and best-performing investments in the world.",
    percentage: 90,
  });

  /* STOCK TILE */
  const StockTile = (props) => {
    return (
      <div
        className="cursor-pointer w-full rounded-lg p-3 hover:shadow-md transition-all duration-300 ease-in-out"
        onClick={props.onClick}
        style={{
          backgroundColor:
            props.name === selectedInvestment?.name
              ? props.backgroundColor
              : "white",
          border: `${
            props.name === selectedInvestment?.name
              ? `1px solid ${props.borderColor}`
              : "1px solid #C9C9C9"
          }`,
        }}
      >
        <h5 className="text-md font-redhat-bold text-black">{props.name}</h5>
        <div className="mt-1">
          <p className="text-sm font-redhat-semibold text-black text-opacity-80">
            {props.description}
          </p>
          <p className="text-sm font-redhat-semibold text-black text-opacity-80">
            Historical return: {props.returnPercentage}
            {props?.endingText}
          </p>
        </div>
      </div>
    );
  };

  /* HANDLE FORM SUBMIT USING FORMIK */
  const formFormikFunction = useFormik({
    /* SET INITIAL VALUES FOR LOGIN FORM */
    initialValues: {
      principalAmount: 460000,
      monthlyContribution: 1500,
      interestRate: 11.77,
      timePeriodRange: { value: 1, label: "Per Month" },
      timePeriod: 10,
    },
    /* VALIDATION SCHEMA  */
    validationSchema: formValidationSchema,
    /* HANDLE FORM SUBMIT */
    onSubmit: (values) => {
      /* SET LOADING AS TRUE */
      setInitialLoad(true);
      setSubmitLoading(true);
      const result = calculateCompoundInterest(values);
      setLabels(result?.labels);
      setGraphData(result?.graphData);
      setWithoutInvestment(result?.withoutInvestmentGraph);
      setEstimatedInvestment(result);
      setSubmitLoading(false);
    },
  });
  useEffect(() => {
    const result = calculateCompoundInterest({
      principalAmount: 460000,
      monthlyContribution: 1500,
      interestRate: 11.77,
      timePeriodRange: {
        value: 1,
        label: "Monthly",
      },
      timePeriod: 10,
    });
    setLabels(result?.labels);
    setGraphData(result?.graphData);
    setEstimatedInvestment(result);
  }, []);

  useEffect(() => {
    formFormikFunction.handleSubmit();
  }, [formFormikFunction.values]);

  /* ACCORDIONS */
  const [accordion01, setAccordion01] = useState(false);
  const [accordion02, setAccordion02] = useState(false);
  const [accordion03, setAccordion03] = useState(false);
  const [accordion04, setAccordion04] = useState(false);

  /* COUNTER BOX */
  const CounterBox = (props) => {
    return (
      <div className="w-full bg-counterBox rounded-lg p-5 xl:p-10 lg:p-10">
        <h2 className="text-[7vh] xl:text-[10vh] lg:text-[10vh] font-redhat-extrabold text-white text-center leading-none">
          {props.value}
        </h2>
        <h5 className="mt-3 xl:mt-0 lg:mt-0 text-2xl xl:text-3xl lg:text-3xl font-redhat-regular text-white text-center">
          {props.label}
        </h5>
      </div>
    );
  };

  /* INVESTOR BOX */
  const InvestorBox = (props) => {
    return (
      <div
        className={`w-full ${
          selectedInvestment?.id === props?.id
            ? "bg-logoGreen"
            : "bg-white hover:bg-black hover:bg-opacity-5"
        } border border-greyBorder border-opacity-50 shadow-md rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={props.onClick}
      >
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-14 h-14 flex-shrink-0"
            alt={props.name}
          />
          <div className="p-0">
            <h5
              className={`text-md font-redhat-bold ${
                selectedInvestment?.id === props?.id
                  ? "text-white"
                  : "text-black"
              }`}
            >
              {props.name}
            </h5>
            <p
              className={`text-sm font-redhat-semibold ${
                selectedInvestment?.id === props?.id
                  ? "text-white text-opacity-80"
                  : "text-greyText"
              }`}
            >
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* BROKER SELECTION BOX */
  const ETFSelectionBox = (props) => {
    return (
      <div
        className={`${
          selectedInvestment?.id === props?.id
            ? "bg-logoGreen"
            : "bg-white hover:bg-[#F2F2F2]"
        } border border-greyBorder border-opacity-50 shadow-md rounded-md p-2 cursor-pointer transition-all duration-300 ease-in-out`}
        onClick={props.onClick}
      >
        <div className="flex items-center gap-3">
          <img
            src={props.image}
            className="w-14 h-14 flex-shrink-0"
            alt={props.strategyName}
          />
          <div className="p-0">
            <h5
              className={`text-md font-redhat-bold ${
                selectedInvestment?.id === props?.id
                  ? "text-white"
                  : "text-black"
              }`}
            >
              {props.name}
            </h5>
            <p
              className={`text-sm font-redhat-semibold ${
                selectedInvestment?.id === props?.id
                  ? "text-white text-opacity-80"
                  : "text-greyText"
              }`}
            >
              {props.strategyName}
            </p>
          </div>
        </div>
      </div>
    );
  };

  /* INVESTMENT VALUES */
  const InvestmentValue = (props) => {
    return (
      <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
        <CardBody className="p-5">
          <h5 className="text-xl font-redhat-bold text-black">{props.value}</h5>
          <div className="flex items-center gap-2 mt-1">
            {props.color !== "#FFF" && (
              <div
                className="w-2 h-2 rounded-full flex-shrink-0"
                style={{ backgroundColor: props.color }}
              />
            )}

            <p className="text-sm font-redhat-regular text-black">
              {props.label}
            </p>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* ABOUT INVESTOR */
  const AboutInvestor = ({ data }) => {
    return (
      <Card className="h-full shadow-none bg-transparent">
        <CardBody className="p-0">
          <img
            src={data?.image}
            className="w-full h-[30vh] object-cover rounded-md mx-auto"
            alt="Jack Bogle"
          />

          <div className="mt-3">
            <h5 className="text-lg font-redhat-bold text-black">
              {data?.heading}
            </h5>
          </div>
          <div className="mt-2">
            <ul className="ml-5 list-disc">
              {data?.list?.map((el) => {
                return (
                  <li className="text-base font-redhat-regular text-black">
                    {el}
                  </li>
                );
              })}
            </ul>
          </div>
        </CardBody>
      </Card>
    );
  };

  /* PROGRESS BAR */
  const ProgressBar = (props) => {
    return (
      <div className="w-full h-3 rounded-full bg-gradient-to-r from-black to-[#219653] relative flex items-center">
        <div
          style={{ width: `${props.percentage}%` }}
          className="flex items-end"
        >
          <div className="w-2 h-2 bg-white rounded-full ml-auto" />
        </div>
      </div>
    );
  };

  /* COMPOSITION */
  const CompositionInvestor = (props) => {
    return (
      <div className="w-full flex items-center gap-1">
        {props?.details?.map((element, index) => {
          return (
            <div
              key={index}
              className={`h-${
                selectedComposition?.name === element?.name ? "16" : "14"
              } bg-[#008037] bg-opacity-${
                selectedComposition?.name === element?.name
                  ? "80"
                  : index === 0
                  ? "20"
                  : index === 1
                  ? "40"
                  : "60"
              } hover:bg-opacity-30 transition-all duration-300 ease-in-out cursor-pointer ${
                index === 0 && "rounded-l-md"
              } ${
                index === props?.details?.length - 1 && "rounded-r-md"
              } flex items-center justify-center`}
              style={{ width: `${element.percentage}%` }}
              onClick={() => {
                setSelectedComposition(element);
              }}
            >
              <p className="text-md font-redhat-semibold text-black">
                {element?.percentage}%
              </p>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    if (
      formFormikFunction.values.interestRate !== "" &&
      formFormikFunction.values.monthlyContribution !== "" &&
      formFormikFunction.values.principalAmount !== "" &&
      formFormikFunction.values.timePeriod !== "" &&
      formFormikFunction.values.timePeriodRange !== ""
    ) {
      formFormikFunction.handleSubmit();
    }
  }, [formFormikFunction.values]);

  return (
    <motion.section
      variants={containerAnimation}
      initial="hidden"
      animate="visible"
      className="bg-transparent"
    >
      {/* NEW CALCULATOR */}
      <motion.div
        variants={animation}
        className="py-6 xl:py-12 lg:py-12"
        id="calculator"
      >
        <div className="container mx-auto">
          {/* HEADING */}
          <div className="p-0">
            <h1 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black leading-tight">
              Select your investment
            </h1>
            <p className="mt-1 text-lg font-redhat-regular text-black">
              Automate Legendary Investors and Powerful ETFs from inside your
              own account
            </p>
          </div>

          {/* BODY */}
          <div className="mt-5">
            <Tabs value={activeTab}>
              <TabsHeader
                className="bg-transparent p-0 shadow-none rounded-none gap-2"
                indicatorProps={{
                  className:
                    "bg-transparent shadow-none bg-[#F6F6F6] rounded-t-md rounded-b-none",
                }}
              >
                <Tab
                  key="Legendary Investors"
                  value="Legendary Investors"
                  className="text-base font-redhat-semibold text-black bg-white px-8 py-4 whitespace-nowrap shadow-none rounded-t-md rounded-b-none"
                  onClick={() => {
                    formFormikFunction.setFieldValue(
                      "interestRate",
                      stocksList?.filter((el) => el?.type === "investor")[0]
                        ?.returnPercentage
                    );
                    setSelectedInvestment(
                      stocksList?.filter((el) => el?.type === "investor")[0]
                    );
                    setSelectedComposition(
                      stocksList?.filter((el) => el?.type === "investor")[0]
                        ?.investorDetails?.composition?.[0]
                    );
                    setBenefits(1);
                    setDrawbacks(1);
                    setActiveTab("Legendary Investors");
                  }}
                >
                  Legendary Investors
                </Tab>
                <Tab
                  key="Powerful ETFs"
                  value="Powerful ETFs"
                  className="text-base font-redhat-semibold text-black bg-white px-8 py-4 whitespace-nowrap shadow-none rounded-t-md rounded-b-none"
                  onClick={() => {
                    formFormikFunction.setFieldValue(
                      "interestRate",
                      stocksList?.filter((el) => el?.type === "etf")[0]
                        ?.returnPercentage
                    );
                    setSelectedInvestment(
                      stocksList?.filter((el) => el?.type === "etf")[0]
                    );
                    setSelectedComposition(
                      stocksList?.filter((el) => el?.type === "etf")[0]
                        ?.investorDetails?.composition?.[0]
                    );
                    setBenefits(1);
                    setDrawbacks(1);
                    setActiveTab("Powerful ETFs");
                  }}
                >
                  Powerful ETFs
                </Tab>
              </TabsHeader>
              <TabsBody className="p-0">
                {/* LEGENDARY INVESTORS */}
                <TabPanel value="Legendary Investors" className="p-0">
                  <div className="bg-[#F6F6F6] p-5 rounded-b-md">
                    <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-3">
                      {stocksList
                        ?.filter((el) => el?.type === "investor")
                        .map((data, index) => (
                          <div className="col-span-1" key={index}>
                            <InvestorBox
                              id={data.id}
                              name={data.name}
                              image={data.image}
                              strategyName={data.strategyName}
                              onClick={() => {
                                formFormikFunction.setFieldValue(
                                  "interestRate",
                                  data?.returnPercentage
                                );
                                setBenefits(1);
                                setDrawbacks(1);
                                setSelectedComposition(
                                  data?.investorDetails?.composition?.[0]
                                );
                                setSelectedInvestment(data);
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </TabPanel>

                {/* POWERFUL ETFs */}
                <TabPanel value="Powerful ETFs" className="p-0">
                  <div className="bg-[#F6F6F6] p-5 rounded-b-md">
                    {/* ETFs LIST */}
                    <Swiper
                      navigation
                      modules={[Navigation, EffectFade]}
                      effect="fade"
                      fadeEffect={{ crossFade: false }}
                      slidesPerView={4}
                      spaceBetween={15}
                      className="overflow-visible"
                    >
                      <SwiperSlide>
                        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                          {stocksList
                            ?.filter((el) => el?.type === "etf")
                            .slice(0, 4)
                            .map((data, index) => (
                              <div className="col-span-1" key={index}>
                                <ETFSelectionBox
                                  id={data.id}
                                  name={data.name}
                                  image={data.image}
                                  strategyName={data.strategyName}
                                  onClick={() => {
                                    formFormikFunction.setFieldValue(
                                      "interestRate",
                                      data?.returnPercentage
                                    );
                                    setSelectedInvestment(data);
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <div className="grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-5">
                          {stocksList
                            ?.filter((el) => el?.type === "etf")
                            .slice(4, 8)
                            .map((data, index) => (
                              <div className="col-span-1" key={index}>
                                <ETFSelectionBox
                                  id={data.id}
                                  name={data.name}
                                  image={data.image}
                                  strategyName={data.strategyName}
                                  onClick={() => {
                                    formFormikFunction.setFieldValue(
                                      "interestRate",
                                      data?.returnPercentage
                                    );
                                    setSelectedInvestment(data);
                                  }}
                                />
                              </div>
                            ))}
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </TabPanel>
              </TabsBody>
            </Tabs>

            {/* INPUT DATA AND CHART */}
            <div className="bg-[#F6F6F6] p-5 pt-0 rounded-b-md grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5">
              {/* CALCULATOR DATA */}
              <div className="col-span-1">
                <Card className="bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                  <CardBody className="p-5">
                    <h5 className="text-lg font-redhat-bold text-black">
                      Investment Calculator
                    </h5>
                    <form
                      className="mt-3"
                      onSubmit={formFormikFunction.handleSubmit}
                    >
                      <div className="form-group">
                        <label className="text-base font-redhat-semibold text-black">
                          How much do you have invested now?
                        </label>
                        <div className="mt-2 relative">
                          <CurrencyFormat
                            thousandSeparator={true}
                            value={formFormikFunction.values.principalAmount}
                            onValueChange={(values) => {
                              const { value } = values;
                              formFormikFunction.setFieldValue(
                                "principalAmount",
                                value
                              );
                            }}
                            hintText="10000"
                            className="w-full block bg-[#F6F6F6] rounded-md px-5 py-2.5 outline-none focus:outline-none text-base placeholder:font-redhat-semibold text-black"
                          />
                          {/* <InputBox
                          type="number"
                          name="principalAmount"
                          id="principalAmount"
                          placeholder="10000"
                          value={formFormikFunction.values.principalAmount}
                          onChange={formFormikFunction.handleChange}
                          error={
                            formFormikFunction.errors.principalAmount &&
                            formFormikFunction.touched.principalAmount
                              ? true
                              : false
                          }
                        /> */}

                          <div className="absolute top-3 right-5">
                            <p className="text-base font-redhat-semibold text-greyText">
                              USD
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label className="text-base font-redhat-semibold text-black">
                          How many years will you invest for?
                        </label>
                        <div className="mt-2 relative">
                          <InputBox
                            type="number"
                            name="timePeriod"
                            id="timePeriod"
                            placeholder="10"
                            value={formFormikFunction.values.timePeriod}
                            onChange={formFormikFunction.handleChange}
                            error={
                              formFormikFunction.errors.timePeriod &&
                              formFormikFunction.touched.timePeriod
                                ? true
                                : false
                            }
                          />

                          <div className="absolute top-3 right-5">
                            <p className="text-base font-redhat-semibold text-greyText">
                              Years
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label className="text-base font-redhat-semibold text-black">
                          How often will you invest more?
                        </label>
                        <div className="mt-2">
                          <Select
                            options={contributingOptions}
                            classNamePrefix={
                              formFormikFunction.errors.timePeriodRange &&
                              formFormikFunction.touched.timePeriodRange
                                ? "order-error"
                                : "order"
                            }
                            placeholder="Select contribution"
                            className="react-select"
                            value={formFormikFunction.values.timePeriodRange}
                            onChange={(e) => {
                              formFormikFunction.setFieldValue(
                                "timePeriodRange",
                                e
                              );
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group mt-4">
                        <label className="text-base font-redhat-semibold text-black">
                          How much will you invest each time?
                        </label>
                        <div className="mt-2 relative">
                          <InputBox
                            type="number"
                            name="monthlyContribution"
                            id="monthlyContribution"
                            placeholder="1500"
                            value={
                              formFormikFunction.values.monthlyContribution
                            }
                            onChange={formFormikFunction.handleChange}
                            error={
                              formFormikFunction.errors.monthlyContribution &&
                              formFormikFunction.touched.monthlyContribution
                                ? true
                                : false
                            }
                          />

                          <div className="absolute top-3 right-5">
                            <p className="text-base font-redhat-semibold text-greyText">
                              USD
                            </p>
                          </div>
                        </div>
                      </div>
                      <Button
                        className="mt-5 w-full shadow-none hover:shadow-none bg-logoGreen hover:bg-opacity-80 rounded-md normal-case text-base font-redhat-bold transition-all duration-300 ease-in-out"
                        type="submit"
                        disabled={submitLoading}
                        onClick={() => {
                          setInitialLoad(false);
                        }}
                        // onClick={() => setStrategySetup(!strategySetup)}
                      >
                        {submitLoading
                          ? "Please wait..."
                          : `✨ Automate ${
                              selectedInvestment?.strategyName
                                ? selectedInvestment?.type === "etf"
                                  ? selectedInvestment?.strategyName
                                  : selectedInvestment?.name
                                : ""
                            }`}
                        {/* {selectedInvestor} */}
                      </Button>
                    </form>
                  </CardBody>
                </Card>
              </div>

              {/* CHART DATA */}
              <div className="col-span-2">
                {/* STATISTICS */}
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                  <div className="col-span-1">
                    <InvestmentValue
                      label={"Your invested amount"}
                      value={SET_FORMAT(
                        estimatedInvestment?.totalAmountInvested
                          ? estimatedInvestment?.totalAmountInvested
                          : 0
                      )}
                      color={"#50CFD7"}
                    />
                  </div>

                  <div className="col-span-1">
                    <InvestmentValue
                      label={"Estimated investment gain"}
                      value={SET_FORMAT(
                        estimatedInvestment?.totalAmount
                          ? estimatedInvestment?.totalAmount -
                          estimatedInvestment?.totalAmountInvested
                          : 0
                      )}
                      color={"#008037"}
                    />
                  </div>

                  <div className="col-span-1">
                    <InvestmentValue
                      label={"Estimated total value"}
                      value={SET_FORMAT(
                        estimatedInvestment?.totalAmount
                          ? estimatedInvestment?.totalAmount
                          : 0
                      )}
                      color={"#FFF"}
                    />
                  </div>
                </div>

                {/* CHART */}
                <Card className="mt-5 bg-white shadow-none rounded-md border border-borderColor border-opacity-20">
                  <CardBody className="p-5">
                    {/* CHARTS */}
                    <div className="-ml-5 -mt-3 -mb-3">
                      <SelectionChart
                        values={graphData}
                        labels={labels}
                        withoutInvestment={withoutInvestment}
                      />
                    </div>
                  </CardBody>
                </Card>
              </div>
            </div>

            {/* INVESTOR BASED DATA */}
            <div className="mt-5">
              <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-5">
                <div className="col-span-1">
                  <div className="bg-[#F6F6F6] p-5 rounded-md">
                    {selectedInvestment && (
                      <AboutInvestor
                        data={selectedInvestment?.investorDetails}
                      />
                    )}
                  </div>
                </div>
                <div className="col-span-1">
                  <Card className="h-full bg-[#F6F6F6] shadow-none rounded-md">
                    <CardBody className="p-5">
                      <h5 className="text-lg font-redhat-bold text-black">
                        About this strategy
                      </h5>
                      <div className="mt-1">
                        <p className="text-base font-redhat-regular text-black">
                          {selectedInvestment?.about}
                        </p>
                      </div>
                      <div className="mt-4">
                        <div className="flex items-center justify-between">
                          <p className="text-base font-redhat-semibold text-black">
                            Estimated Return
                          </p>
                          <p className="text-base font-redhat-semibold text-black">
                            {selectedInvestment?.returnPercentage}% per year (
                            {selectedInvestment?.returnPercentage
                              ? selectedInvestment?.returnPercentage > 10
                                ? "High"
                                : selectedInvestment?.returnPercentage <= 10 &&
                                  selectedInvestment?.returnPercentage >= 8
                                ? "Medium"
                                : "Low"
                              : "Low"}
                            )
                          </p>
                        </div>
                        <div className="mt-2">
                          <ProgressBar
                            percentage={
                              selectedInvestment?.returnPercentage
                                ? selectedInvestment?.returnPercentage > 10
                                  ? 99
                                  : selectedInvestment?.returnPercentage <=
                                      10 &&
                                    selectedInvestment?.returnPercentage >= 8
                                  ? 66
                                  : 33
                                : 33
                            }
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <div className="flex items-center justify-between">
                          <p className="text-base font-redhat-semibold text-black">
                            Risk Level
                          </p>
                          <p className="text-base font-redhat-semibold text-black">
                            {selectedInvestment?.riskScore} / 5.0 (
                            {selectedInvestment?.riskScore
                              ? selectedInvestment?.riskScore > 3.5
                                ? "High"
                                : selectedInvestment?.riskScore <= 3.5 &&
                                  selectedInvestment?.riskScore >= 2
                                ? "Medium"
                                : "Low"
                              : "Low"}
                            )
                          </p>
                        </div>
                        <div className="mt-2">
                          <ProgressBar
                            percentage={
                              selectedInvestment?.riskScore
                                ? selectedInvestment?.riskScore > 3.5
                                  ? 99
                                  : selectedInvestment?.riskScore <= 3.5 &&
                                    selectedInvestment?.riskScore >= 2
                                  ? 66
                                  : 33
                                : 33
                            }
                          />
                        </div>
                      </div>
                      {selectedInvestment?.investorDetails?.composition ? (
                        <>
                          <div className="mt-3">
                            <p className="text-base font-redhat-semibold text-black">
                              The composition of the investment portfolio
                            </p>
                          </div>
                          <div className="mt-2">
                            {selectedInvestment?.investorDetails
                              ?.composition && (
                              <CompositionInvestor
                                details={
                                  selectedInvestment?.investorDetails
                                    ?.composition
                                }
                              />
                            )}
                          </div>
                          <div className="mt-3 bg-white p-3">
                            {selectedComposition && (
                              <div className="flex items-center gap-2">
                                <div className="w-4 h-4 bg-[#008037] rounded" />
                                <p className="text-md font-redhat-semibold text-black">
                                  {selectedComposition?.percentage}% is invested
                                  into {selectedComposition?.name}
                                </p>
                              </div>
                            )}
                            <p className="text-sm font-redhat-semibold text-black mt-2">
                              {selectedComposition?.description}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="mt-5">
                          <p className="text-base font-redhat-semibold text-black">
                            Top holdings
                          </p>
                          <div className="mt-3 bg-white rounded-md p-3">
                            {selectedInvestment?.investorDetails?.holdings?.map(
                              (element, index) => {
                                return element?.link === false ? (
                                  <div
                                    key={index}
                                    className={`flex items-center justify-between ${
                                      index !== 0 && "mt-2"
                                    }`}
                                  >
                                    <p className="text-base font-redhat-semibold text-black">
                                      {element?.name}
                                    </p>
                                    <p className="text-base font-redhat-semibold text-black">
                                      {element?.percentage}%
                                    </p>
                                  </div>
                                ) : (
                                  <Link to={element?.redirectLink}>
                                    <Button className="mt-3 w-full text-center py-2 shadow-none hover:shadow-none bg-white hover:bg-logoGreen hover:bg-opacity-5 rounded-md transition-all duration-300 ease-in-out text-logoGreen normal-case font-redhat-semibold text-base">
                                      See the full list
                                    </Button>
                                  </Link>
                                );
                              }
                            )}
                          </div>
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </div>
                <div className="col-span-1">
                  <Card className="h-full shadow-none bg-[#F6F6F6] rounded-lg">
                    <CardBody className="p-5">
                      <h5 className="text-lg font-redhat-bold text-black">
                        Benefits
                      </h5>
                      <div className="mt-3">
                        <Card className="bg-white w-full rounded-md shadow-none">
                          <CardBody className="p-5">
                            <Swiper
                              modules={[Navigation, EffectFade]}
                              navigation={{
                                nextEl: ".benefit-next",
                                prevEl: ".benefit-prev",
                              }}
                              slidesPerView={1}
                              effect="fade"
                              fadeEffect={{ crossFade: true }}
                            >
                              {selectedInvestment?.investorDetails?.benefits?.map(
                                (element, index) => {
                                  return (
                                    <SwiperSlide key={index}>
                                      <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                        {element?.description}
                                      </p>
                                    </SwiperSlide>
                                  );
                                }
                              )}
                            </Swiper>
                            <div className="mt-7 flex items-center justify-between">
                              <Button
                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-prev"
                                onClick={() => {
                                  setBenefits(benefits - 1);
                                }}
                              >
                                <FaChevronLeft className="w-4 h-4 text-black" />
                              </Button>
                              <div className="flex items-center gap-2">
                                {selectedInvestment?.investorDetails?.benefits?.map(
                                  (element, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={`w-2.5 h-2.5 rounded-full ${
                                          index + 1 === benefits
                                            ? "bg-[#008037]"
                                            : "bg-[#DDD]"
                                        }`}
                                      />
                                    );
                                  }
                                )}
                              </div>
                              <Button
                                className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out benefit-next"
                                onClick={() => {
                                  setBenefits(benefits + 1);
                                }}
                              >
                                <FaChevronRight className="w-4 h-4 text-black" />
                              </Button>
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                      {console.log("benefits", benefits)}
                      {console.log("drawbacks", drawbacks)}
                      <div className="mt-5">
                        <h5 className="text-lg font-redhat-bold text-black">
                          Drawbacks
                        </h5>
                        <div className="mt-3">
                          <Card className="bg-white w-full rounded-md shadow-none">
                            <CardBody className="p-5">
                              <Swiper
                                modules={[Navigation, EffectFade]}
                                navigation={{
                                  nextEl: ".drawback-next",
                                  prevEl: ".drawback-prev",
                                }}
                                slidesPerView={1}
                                effect="fade"
                                fadeEffect={{ crossFade: true }}
                              >
                                {selectedInvestment?.investorDetails?.drawbacks?.map(
                                  (element, index) => {
                                    return (
                                      <SwiperSlide key={index}>
                                        <p className="w-11/12 mx-auto text-sm font-redhat-semibold text-black text-center">
                                          {element?.description}
                                        </p>
                                      </SwiperSlide>
                                    );
                                  }
                                )}
                              </Swiper>

                              <div className="mt-7 flex items-center justify-between">
                                <Button
                                  className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out drawback-prev"
                                  onClick={() => {
                                    setDrawbacks(drawbacks - 1);
                                  }}
                                >
                                  <FaChevronLeft className="w-4 h-4 text-black" />
                                </Button>
                                <div className="flex items-center gap-2">
                                  {selectedInvestment?.investorDetails?.drawbacks?.map(
                                    (element, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className={`w-2.5 h-2.5 rounded-full ${
                                            index + 1 === drawbacks
                                              ? "bg-[#008037]"
                                              : "bg-[#DDD]"
                                          }`}
                                        />
                                      );
                                    }
                                  )}
                                </div>
                                <Button
                                  className="shadow-none hover:shadow-none p-2 rounded-full bg-white hover:bg-black hover:bg-opacity-5 transition-all duration-300 ease-in-out drawback-next"
                                  onClick={() => {
                                    setDrawbacks(drawbacks + 1);
                                  }}
                                >
                                  <FaChevronRight className="w-4 h-4 text-black" />
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* CALCULATOR */}
      <motion.div
        variants={animation}
        className="hidden py-6 xl:py-12 lg:py-12"
        id="calculator"
      >
        <div className="container mx-auto">
          <h1 className="text-3xl xl:text-5xl lg:text-5xl font-redhat-extrabold text-gradient leading-tight xl:leading-normal lg:leading-normal">
            We make investing so easy, anyone can do it.
          </h1>
          <h2 className="mt-5 xl:mt-0 lg:mt-0 text-xl xl:text-2xl lg:text-2xl font-redhat-regular text-black">
            Calculate your path to financial freedom with the stock market, and
            automate it! 👇
          </h2>

          {/* CALCULATOR CONTAINER */}
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <Card className="bg-white border border-inputBox border-opacity-50 rounded-2xl shadow-md">
              <CardBody className="p-4 xl:p-8 lg:p-8">
                {/* CHART AND DETAILS */}
                <div className="grid grid-cols-1 xl:grid-cols-3 lg:grid-cols-3 gap-0 xl:gap-5 lg:gap-5">
                  {/* DETAILS BOX */}
                  <div className="col-span-1">
                    <div className="bg-calcBlue p-5 rounded-lg">
                      <form
                        className="p-0"
                        onSubmit={formFormikFunction.handleSubmit}
                      >
                        <div className="form-group">
                          <label className="text-base font-redhat-semibold text-white">
                            How much do you have invested now?
                          </label>
                          <div className="mt-2">
                            <InputBox
                              type="number"
                              name="principalAmount"
                              id="principalAmount"
                              value={formFormikFunction.values.principalAmount}
                              onChange={formFormikFunction.handleChange}
                              error={
                                formFormikFunction.errors.principalAmount &&
                                formFormikFunction.touched.principalAmount
                                  ? true
                                  : false
                              }
                              placeholder="How much have you invested now"
                            />
                          </div>
                        </div>
                        <div className="form-group mt-5">
                          <label className="text-base font-redhat-semibold text-white">
                            How many years will you invest for?
                          </label>
                          <div className="mt-2">
                            <InputBox
                              type="number"
                              name="timePeriod"
                              id="timePeriod"
                              value={formFormikFunction.values.timePeriod}
                              onChange={formFormikFunction.handleChange}
                              error={
                                formFormikFunction.errors.timePeriod &&
                                formFormikFunction.touched.timePeriod
                                  ? true
                                  : false
                              }
                              placeholder="How many years will you invest for"
                            />
                          </div>
                        </div>
                        <div className="form-group mt-5">
                          <label className="text-base font-redhat-semibold text-white">
                            How much will you contribute monthly?
                          </label>
                          <div className="mt-2">
                            <InputBox
                              type="number"
                              name="monthlyContribution"
                              id="monthlyContribution"
                              value={
                                formFormikFunction.values.monthlyContribution
                              }
                              onChange={formFormikFunction.handleChange}
                              error={
                                formFormikFunction.errors.monthlyContribution &&
                                formFormikFunction.touched.monthlyContribution
                                  ? true
                                  : false
                              }
                              placeholder="How much will you contribute monthly"
                            />
                          </div>
                        </div>
                        <div className="form-group mt-5">
                          <label className="text-base font-redhat-semibold text-white">
                            How often will you contribute?
                          </label>
                          <div className="mt-2">
                            <Select
                              options={contributionOptions}
                              classNamePrefix={
                                formFormikFunction.errors.timePeriodRange &&
                                formFormikFunction.touched.timePeriodRange
                                  ? "order-error"
                                  : "order"
                              }
                              placeholder="Select contribution"
                              className="react-select"
                              value={formFormikFunction.values.timePeriodRange}
                              onChange={(e) => {
                                formFormikFunction.setFieldValue(
                                  "timePeriodRange",
                                  e
                                );
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group mt-5">
                          <label className="text-base font-redhat-semibold text-white">
                            What will you invest in? (select below)
                          </label>
                          <div className="mt-2">
                            <StockTile
                              name={selectedInvestment?.name}
                              description={selectedInvestment?.description}
                              returnPercentage={
                                selectedInvestment?.returnPercentage
                              }
                              endingText={selectedInvestment?.endingText}
                              backgroundColor={
                                selectedInvestment?.backgroundColor
                              }
                              isSelected={true}
                              borderColor={selectedInvestment?.borderColor}
                            />
                          </div>
                        </div>
                        <Button
                          type="submit"
                          disabled={submitLoading}
                          className="mt-5 blue-gradient shadow-none hover:shadow-none text-base font-redhat-semibold text-white normal-case rounded-md w-full transition-all duration-300 ease-in-out"
                        >
                          {submitLoading
                            ? "Please wait..."
                            : "✨ Automate this investment plan"}
                        </Button>
                      </form>
                    </div>
                  </div>

                  {/* CHART */}
                  <div className="col-span-2 mt-5 xl:mt-0 lg:mt-0">
                    <div className="w-full px-5 bg-white border border-inputBox border-opacity-50 rounded-md">
                      <div className="text-center py-3 border-b border-inputBox">
                        <h5 className="text-xl font-redhat-bold text-black text-center">
                          Your estimated investment
                        </h5>
                        {estimatedInvestment?.totalAmount === undefined ? (
                          <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black text-center">
                            -
                          </h5>
                        ) : (
                          <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black text-center">
                            $
                            {parseFloat(
                              parseFloat(
                                estimatedInvestment?.totalAmount
                              ).toFixed(2)
                            ).toLocaleString("en-US")}
                          </h5>
                        )}
                        {/* <h5 className="text-xl xl:text-2xl lg:text-2xl font-redhat-bold text-black text-center">
                          $ 1,124,887.31
                        </h5> */}
                      </div>
                      <div className="py-3 flex items-center justify-center gap-10">
                        <h5 className="text-lg xl:text-xl lg:text-xl font-redhat-bold text-black text-center">
                          Investment: {selectedInvestment?.symbol}
                        </h5>
                        <h5 className="text-lg xl:text-xl lg:text-xl font-redhat-bold text-black text-center">
                          Risk Score: {selectedInvestment?.riskScore}/5 (
                          {selectedInvestment?.riskScore
                            ? selectedInvestment?.riskScore > 3.5
                              ? "High"
                              : selectedInvestment?.riskScore <= 3.5 &&
                                selectedInvestment?.riskScore >= 2
                              ? "Medium"
                              : "Low"
                            : "Low"}
                          )
                        </h5>
                      </div>
                    </div>
                    <div className="mt-5">
                      <DashboardChart values={graphData} labels={labels} />
                    </div>
                  </div>
                </div>

                {/* SELECT INVESTMENT */}
                <div className="mt-5">
                  <h2 className="text-lg font-redhat-bold text-black">
                    Select an investment
                  </h2>
                  <div className="mt-2 grid grid-cols-1 xl:grid-cols-4 lg:grid-cols-4 gap-y-3 xl:gap-5 lg:gap-5">
                    {investmentOptions.map((data) => (
                      <div className="col-span-1" key={data.id}>
                        <StockTile
                          name={data?.name}
                          description={data?.description}
                          returnPercentage={data?.returnPercentage}
                          endingText={data?.endingText}
                          backgroundColor={data?.backgroundColor}
                          borderColor={data?.borderColor}
                          onClick={() => {
                            formFormikFunction.setFieldValue(
                              "interestRate",
                              data?.returnPercentage
                            );
                            setSelectedInvestment(data);
                            setStock({
                              name: data.name,
                              description: data.description,
                              returnPercentage: data.returnPercentage,
                              endingText: data.endingText,
                              symbol: data?.symbol,
                            });
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </motion.div>

      {/* OUR PROCESS */}
      <motion.div
        variants={animation}
        className="pt-5 xl:pt-12 lg:pt-12 pb-10 xl:pb-40 lg:pb-40 relative"
      >
        <div className="container mx-auto">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black">
            How does ScaleTrade automated investing work?
          </h2>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            {/* STEP ONE */}
            <Card className="block shadow-lg bg-[#F6F6F6] border border-greyBorder border-opacity-20 rounded-2xl">
              <CardBody className="p-3">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-0">
                  <div className="col-span-1">
                    <img
                      src={Step01}
                      className="w-full h-auto object-cover rounded-lg"
                      alt="Step 1: Calculate your investing plan"
                    />
                  </div>
                  <div className="col-span-1 px-0 xl:px-10 lg:px-10 py-5">
                    <h2 className="text-1xl font-redhat-extrabold text-black">
                      Step 1: Calculate your investing plan
                    </h2>
                    <div className="mt-5">
                      <p className="text-lg font-redhat-regular text-black">
                        Use our investment calculator to see how much your
                        estimated investments will be worth in the future!
                      </p>
                      <p className="mt-5 text-lg font-redhat-regular text-black">
                        Explore a few of the most common investments to
                        understand which investing plan works best for you.
                      </p>
                    </div>
                    <div className="mt-8">
                      <Button
                        onClick={() => {
                          setCurrentTab("register");
                          setCurrentState(true);
                        }}
                        className="shadow-none hover:shadow-none bg-transparent hover:bg-black border border-greyBorder hover:border-black rounded-lg normal-case transition-all duration-300 ease-in-out font-redhat-semibold text-md text-black hover:text-white"
                      >
                        See how much your money could grow
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* STEP TWO */}
            <Card className="mt-5 block shadow-lg bg-[#F6F6F6] border border-greyBorder border-opacity-20 rounded-2xl">
              <CardBody className="p-3">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-0">
                  <div className="col-span-1">
                    <img
                      src={Step02}
                      className="w-full h-auto object-cover rounded-lg"
                      alt="Step 2: Connect your brokerage"
                    />
                  </div>
                  <div className="col-span-1 px-0 xl:px-10 lg:px-10 py-5">
                    <h2 className="text-1xl font-redhat-extrabold text-black">
                      Step 2: Connect your brokerage
                    </h2>
                    <div className="mt-5">
                      <p className="text-lg font-redhat-regular text-black">
                        Automate your investment plan from inside your own
                        brokerage account. Connect to get started.
                      </p>
                      <p className="mt-5 text-lg font-redhat-regular text-black">
                        Don't have a brokerage account yet? Here's our list of
                        our recommended brokerages.
                      </p>
                    </div>
                    <div className="mt-8">
                      <Button
                        onClick={() => {
                          setCurrentTab("register");
                          setCurrentState(true);
                        }}
                        className="shadow-none hover:shadow-none bg-transparent hover:bg-black border border-greyBorder hover:border-black rounded-lg normal-case transition-all duration-300 ease-in-out font-redhat-semibold text-md text-black hover:text-white"
                      >
                        Connect your brokerage account
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* STEP THREE */}
            <Card className="mt-5 block shadow-lg bg-[#F6F6F6] border border-greyBorder border-opacity-20 rounded-2xl">
              <CardBody className="p-3">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-0">
                  <div className="col-span-1">
                    <img
                      src={Step03}
                      className="w-full h-auto object-cover rounded-lg"
                      alt="Step 3: Start your automated investing"
                    />
                  </div>
                  <div className="col-span-1 px-0 xl:px-10 lg:px-10 py-5">
                    <h2 className="text-1xl font-redhat-extrabold text-black">
                      Step 3: Start your automated investing
                    </h2>
                    <div className="mt-5">
                      <p className="text-lg font-redhat-regular text-black">
                        When you've finalized your investing plan, just press
                        "Start" and you're on your way to stress-free investing.
                      </p>
                      <p className="mt-5 text-lg font-redhat-regular text-black">
                        You're able to pause, edit, or remove automations at any
                        time from your ScaleTrade dashboard.
                      </p>
                    </div>
                    <div className="mt-8">
                      <Button
                        onClick={() => {
                          setCurrentTab("register");
                          setCurrentState(true);
                        }}
                        className="shadow-none hover:shadow-none bg-transparent hover:bg-black border border-greyBorder hover:border-black rounded-lg normal-case transition-all duration-300 ease-in-out font-redhat-semibold text-md text-black hover:text-white"
                      >
                        Get started for free
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>

            {/* CARD FOUR */}
            <Card className="mt-5 block shadow-lg bg-[#F6F6F6] border border-greyBorder border-opacity-20 rounded-2xl">
              <CardBody className="p-3">
                <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-0">
                  <div className="col-span-1">
                    <img
                      src={Step04}
                      className="w-full h-auto object-cover rounded-lg"
                      alt="Step 4: Watch it grow on your dashboard!"
                    />
                  </div>
                  <div className="col-span-1 px-0 xl:px-10 lg:px-10 py-5">
                    <h2 className="text-1xl font-redhat-extrabold text-black">
                      Step 4: Watch it grow on your dashboard!
                    </h2>
                    <div className="mt-5">
                      <p className="text-lg font-redhat-regular text-black">
                        Watch your investments grow! Track how risky your
                        current investments are, company dividends, and more.
                      </p>
                      <p className="mt-5 text-lg font-redhat-regular text-black">
                        You can even use ScaleTrade to make trades! And, if you
                        have multiple brokerage accounts, you can trade across
                        all of your accounts at the same time.{" "}
                        <Link to="/" className="text-logoGreen underline">
                          Learn more
                        </Link>
                      </p>
                    </div>
                    <div className="mt-8">
                      <Button
                        onClick={() => {
                          setCurrentTab("register");
                          setCurrentState(true);
                        }}
                        className="shadow-none hover:shadow-none bg-transparent hover:bg-black border border-greyBorder hover:border-black rounded-lg normal-case transition-all duration-300 ease-in-out font-redhat-semibold text-md text-black hover:text-white"
                      >
                        Get started for free
                      </Button>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        {/* BACKGROUND IMAGE */}
        <div className="absolute -top-[55vh] left-0 -z-10">
          <img
            src={BackgroundImage}
            className="w-full h-auto"
            alt="ScaleTrade"
          />
        </div>
      </motion.div>

      {/* DEMO VIDEO */}
      <motion.div variants={animation} className="pt-5 xl:pt-10 lg:pt-10">
        <div className="container mx-auto">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black text-center">
            Watch our demo to experience it first-hand
          </h2>

          <div className="mt-5 xl:mt-10 lg:mt-10 relative">
            <img
              src={VideoPlacholder}
              className="w-full h-auto object-cover"
              alt="Watch our demo to experience it first-hand"
            />

            {/* LINK TO THE VIDEO */}
            <Link to={"https://www.youtube.com/watch?v=ehgW8dv5N7E&t=10s"}>
              <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-5 hover:bg-opacity-10 transition-all duration-300 ease-in-out flex items-center justify-center cursor-pointer">
                <div className="w-20 h-20 rounded-full bg-black bg-opacity-40 flex items-center justify-center">
                  <FaPlay className="w-10 h-10 text-white text-opacity-70 pl-1" />
                </div>
              </div>
            </Link>
          </div>
        </div>
      </motion.div>

      {/* COUNTERS */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 xl:grid-cols-2 lg:grid-cols-2 gap-5">
            {counters.map((data, index) => (
              <div className="col-span-1" key={index}>
                <CounterBox label={data.label} value={data.value} />
              </div>
            ))}
          </div>
        </div>
      </motion.div>

      {/* FREQUENTLY ASKED QUESTIONS */}
      <motion.div variants={animation} className="py-5 xl:py-12 lg:py-12">
        <div className="container mx-auto">
          <h2 className="text-2xl xl:text-3xl lg:text-3xl font-redhat-extrabold text-black text-center">
            Frequently Asked Questions
          </h2>
          <div className="mt-5 xl:mt-10 lg:mt-10">
            <Accordion
              open={accordion01}
              animate={CUSTOM_ANIMATION}
              className="border-t border-b border-black"
              icon={
                accordion01 ? (
                  <FiMinus className="w-5 h-5 text-black" />
                ) : (
                  <IoMdAdd className="w-5 h-5 text-black" />
                )
              }
            >
              <AccordionHeader
                className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                onClick={() => setAccordion01(!accordion01)}
              >
                How does it work?
              </AccordionHeader>
              <AccordionBody className="pb-3 pt-0 bg-white">
                <p className="text-base font-redhat-regular text-black leading-relaxed">
                  Create an account with ScaleTrade, connect your brokerage
                  account, select the strategy you would like to automate,
                  select the stocks you would like to trade with that strategy,
                  and start the automation!
                </p>
              </AccordionBody>
            </Accordion>

            <div className="mt-0">
              <Accordion
                open={accordion02}
                animate={CUSTOM_ANIMATION}
                className="border-t-0 border-b border-black"
                icon={
                  accordion02 ? (
                    <FiMinus className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdAdd className="w-5 h-5 text-black" />
                  )
                }
              >
                <AccordionHeader
                  className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                  onClick={() => setAccordion02(!accordion02)}
                >
                  How much does it cost?
                </AccordionHeader>
                <AccordionBody className="pb-3 pt-0 bg-white">
                  <p className="text-base font-redhat-regular text-black leading-relaxed">
                    Check out our pricing{" "}
                    <Link to="/pricing" className="text-logoGreen">
                      here.
                    </Link>
                  </p>
                </AccordionBody>
              </Accordion>
            </div>

            <div className="mt-0">
              <Accordion
                open={accordion03}
                animate={CUSTOM_ANIMATION}
                className="border-t-0 border-b border-black"
                icon={
                  accordion03 ? (
                    <FiMinus className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdAdd className="w-5 h-5 text-black" />
                  )
                }
              >
                <AccordionHeader
                  className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                  onClick={() => setAccordion03(!accordion03)}
                >
                  Which strategies does ScaleTrade trade?
                </AccordionHeader>
                <AccordionBody className="pb-3 pt-0 bg-white">
                  <p className="text-base font-redhat-regular text-black leading-relaxed">
                  ScaleTrade offers strategies from professional investors like Warren Buffet, Jack Bogle, and Benjamin Graham. 
                  Scaletrade also offers a list of ETFs from funds like Vanguard and Schwab.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>

            <div className="mt-0">
              <Accordion
                open={accordion04}
                animate={CUSTOM_ANIMATION}
                className="border-t-0 border-b border-black"
                icon={
                  accordion04 ? (
                    <FiMinus className="w-5 h-5 text-black" />
                  ) : (
                    <IoMdAdd className="w-5 h-5 text-black" />
                  )
                }
              >
                <AccordionHeader
                  className="border-b-0 border-transparent bg-white py-3 text-md font-redhat-bold text-black"
                  onClick={() => setAccordion04(!accordion04)}
                >
                  Is it safe to connect my brokerage account to ScaleTrade?
                </AccordionHeader>
                <AccordionBody className="pb-3 pt-0 bg-white">
                  <p className="text-base font-redhat-regular text-black leading-relaxed">
                    Yes, we use bank-level security to connect your account. All
                    information is encrypted, and we use OAuth2 connections
                    where possible to avoid storing your login credentials. User
                    financial data is never shared without the user’s explicit
                    consent.
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          </div>
        </div>
      </motion.div>

      {/* SCROLL TO TOP */}
      <motion.div variants={animation} className="pb-5">
        <div className="container mx-auto">
          <a
            href="#calculator"
            className="block text-lg font-redhat-regular text-black hover:text-logoGreen text-center transition-all duration-300 ease-in-out"
          >
            Take me back to the calculator
          </a>
        </div>
      </motion.div>
      <AccountCreation
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        currentState={currentState}
        setCurrentState={setCurrentState}
      />
    </motion.section>
  );
};

export default Homepage;
